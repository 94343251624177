import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

const CardSlider = () => {
const cardSlider = new Swiper('.js-slider', {
  loop: false,
  slidesPerView: 1.2,
  spaceBetween: 20,
  speed: 800,
  loadOnTransitionStart: true,
  loopAdditionalSlides: 1,
  grabCursor: true,
  breakpoints: {
    769: {
      slidesPerView: 3.2,
      spaceBetween: 40,
    },
  },
});
};
const StudySlider = () => {
  const studySlider = new Swiper('.js-study-slider', {
    spaceBetween: 40,
    loop: false,
    slidesPerView: 1.2,
    spaceBetween: 20,
    speed: 800,
    loadOnTransitionStart: true,
    loopAdditionalSlides: 1,
    grabCursor: true,
    breakpoints: {
      769: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
    },
  });
  };

window.addEventListener('load', function(){
  CardSlider();
  StudySlider();
});




class ToggleModal {
  constructor(target) {
    this.target = target;
    this.videoId = target.dataset.url.slice(-11);
    this.loadIframePlayerAPI();
    this.open();
    this.close();
  }
  loadIframePlayerAPI() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }
  onYouTubeIframeAPIReady() {
    let ytPlayer = new YT.Player('player', {
      videoId: this.videoId,
      playerVars: {
        'autoplay': 1,
        'controls': 1
      }
    });
  }
  remakePlayerElement() {
    const modal = document.querySelector('#modal-video');
    modal.removeChild(modal.firstElementChild);
    let tag = document.createElement('div');
    tag.id = 'player';
    modal.appendChild(tag);
  }
  open() {
    this.target.addEventListener('click', () => {
      this.onYouTubeIframeAPIReady();
      document.querySelector('#modal-video').classList.add('open');
      document.querySelector('#modal-video').classList.remove('close');
    });
  }
  close() {
    document.querySelector('.js-modal-video-close').addEventListener('click', () => {
      document.querySelector('#modal-video').classList.add('close');
      document.querySelector('#modal-video').classList.remove('open');
      this.remakePlayerElement();
    });
  }
}




class imageModal {
  constructor(target) {
  this.target = target;
  this.imageId = target.getAttribute('src');
  this.open();
  this.close();
  }

  createImage() {
    const modal = document.querySelector('#modal-video');
    modal.removeChild(modal.firstElementChild);
    let tag = document.createElement('img');
    tag.src = this.imageId;
    modal.appendChild(tag);
  }
  remakePlayerElement() {
    const modal = document.querySelector('#modal-video');
    modal.removeChild(modal.firstElementChild);
    let tag = document.createElement('div');
    tag.id = 'player';
    modal.appendChild(tag);
  }
  open() {
    this.target.addEventListener('click', event => {
      this.createImage();
      document.querySelector('#modal-video').classList.add('open');
      document.querySelector('#modal-video').classList.remove('close');
    });
  }
  close() {
    document.querySelector('.js-modal-video-close').addEventListener('click', event => {
      document.querySelector('#modal-video').classList.add('close');
      document.querySelector('#modal-video').classList.remove('open');
      this.remakePlayerElement();
    });
  }
}


document.addEventListener('DOMContentLoaded', event => {
  document.querySelectorAll('.js-modal-video-open').forEach(e => {
    new ToggleModal(e);
  });
  document.querySelectorAll('.js-modal-image-open img').forEach(e => {
    new imageModal(e);
  });
});